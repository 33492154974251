import React, { useState } from "react";
import axios from "axios";
import { Layout, Button, Modal, Divider } from "antd";

import "./App.css";

const { Content } = Layout;

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState();

  const callApi = async () => {
    await axios
      .get("https://thuc.us/phuclong.php?api=true")
      .then((res) => {
        setModalData(res.data);
        setIsLoading(false);
        setButtonLoading(false);
        setIsModalVisible(true);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setButtonLoading(false);
        setIsModalVisible(false);
      });
  };

  const buttonOutsideClick = () => {
    setIsLoading(true);
    callApi();
  };

  const buttonInsideClick = () => {
    setButtonLoading(true);
    callApi();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Layout
        style={{
          backgroundColor: "white",
          padding: "40px",
        }}
      >
        <Content
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              paddingBottom: "40px",
            }}
          >
            <h1 style={{ fontSize: "2.5rem" }}>
              Auto pick 1 drink from Phuc Long - For you, lazy ass!
            </h1>
          </div>
          <div
            style={{
              paddingBottom: "40px",
            }}
          >
            <img
              src="https://phuclong.com.vn/images/logo_2.png"
              alt="Logo Phuc Long"
            />
          </div>
          <div>
            <Button
              type="primary"
              size="large"
              style={{ background: "#28a745", border: "transparent" }}
              loading={isLoading}
              onClick={() => {
                buttonOutsideClick();
              }}
            >
              Click here!!!
            </Button>
          </div>
        </Content>
      </Layout>
      <Modal
        visible={isModalVisible}
        onOk={buttonInsideClick}
        onCancel={handleCancel}
        footer={[
          <Button
            key="submit"
            type="primary"
            style={{ background: "#28a745", border: "transparent" }}
            loading={buttonLoading}
            onClick={buttonInsideClick}
          >
            Repick
          </Button>,
          <Button key="back" className="okButton" onClick={handleCancel}>
            OK
          </Button>,
        ]}
      >
        <p
          style={{
            margin: 0,
            color: "rgba(0, 0, 0, 0.85)",
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
        >
          {modalData?.name}
        </p>
        <p
          style={{
            margin: 0,
            color: "#6c757d",
            fontWeight: "lighter",
            fontSize: "1rem",
          }}
        >
          {modalData?.desc}
          <span style={{ fontStyle: "italic" }}> ({modalData?.price})</span>
        </p>
        <Divider />
        <img
          src={modalData?.img}
          style={{ width: "100%" }}
          alt="Phuc Long's item"
        />
      </Modal>
    </>
  );
}

export default App;
